<!-- 视频播放组件 -->

<template>
	<video-player class="video-player vjs-custom-skin" :options="playerOptions" />
</template>
<script>
import 'videojs-contrib-hls'
export default {
	props: {
		// 视频播放地址
		url: {
			type: String,
		},
		poster: {
			type: String,
		},
		sources: Array,
	},
	computed: {
		playerOptions() {
			if (this.sources?.length) {
				return {
					width: '640px',
					height: '360px',
					responsive: true,
					muted: true,
					autoplay: false,
					preload: 'auto',
					playbackRates: [0.7, 1.0, 1.5, 2.0],
					sources: this.sources,
					poster: this.poster,
				}
			} else {
				return {
					width: '640px',
					height: '360px',
					responsive: true,
					muted: true,
					autoplay: false,
					playbackRates: [0.7, 1.0, 1.5, 2.0],
				}
			}
		},
		player() {
			return this.$refs.videoPlayer.player
		},
	},
	methods: {},
	beforeDestory() {
		this.player && this.player.dispose()
	},
}
</script>
<style>
.video-js .vjs-big-play-button {
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
</style>
